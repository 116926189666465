<template>
    <div @click="handleClick" class="d-flex flex-column no-gutters fill-height" style="position: relative">
        <div class="fill-height" ref="me" contenteditable @input.stop="handleInput" @keypress.stop="handleKeyPress"  v-text="value" v-show="editing"></div>
        <div v-html="purify(value)" class="editable fill-height" v-if="!editing"></div>
        <div>{{ formatDate(noteDate) }}</div>
        <v-btn icon class="save" @click="saveNote()" v-if="editing"><v-icon class="green--text text--darken-2 font-weight-bold">check</v-icon></v-btn>
        <v-btn icon class="delete" @click="deleteNote()"><v-icon class="black--text text--darken-1">close</v-icon></v-btn>
    </div>
</template>

<script>
import {formatDate, log, purify} from "Helpers/helpers";
import {api} from "Api";

export default {
    name: "DealNote",
    props: ['note','dealId','type','noteDate'],
    data() {
        return {
            editing: false,
            value: ''
        }
    },
    methods: {
        deleteNote() {
            let note = {
                type: this.type
            }
            api
                .put("/hubspot/deals/" + this.dealId + "/note/delete",{
                    note: note
                })
                .then(response => {
                    if(response.data.status == 'success') {
                        this.$toast.success(this.$t('message.successes.dealNoteDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.value = ''
                        this.editing = false
                    } else {
                        this.$toast.error(response.data.status,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.editing = false
                    }
                })
                .catch(error => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.editing = false
                });
        },
        formatDate,
        handleClick () {
            this.editing = true
            setTimeout(()=>{
                this.$refs.me.focus()
            }, 10)
        },
        handleInput () {
            return
        },
        handleKeyPress (e) {
            if (e.target.innerText.length >= this.maxLength) {
                e.preventDefault()
                return
            }
        },
        handleDone (e) {
            this.value = e.target.innerText
            this.updateNote()
                .then(() => {
                    this.$toast.success(this.$t('message.successes.dealNoteAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline'
                        }
                    )
                    this.editing = false
                })
                .catch((e) => {
                    this.$toast.error(e,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.editing = false
                })
        },
        purify,
        saveNote() {
            this.value = this.$refs.me.innerText
            this.updateNote()
                .then(() => {
                    this.$toast.success(this.$t('message.successes.dealNoteAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline'
                        }
                    )
                    this.editing = false
                })
                .catch((e) => {
                    this.$toast.error(e,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.editing = false
                })
        },
        updateNote() {
            return new Promise((resolve, reject) => {
                let note = {
                    note: this.value,
                    type: this.type
                }
                api
                    .put("/hubspot/deals/" + this.dealId + "/note",{
                        note: note
                    })
                    .then(response => {
                        if(response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve(response.data.message)
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    },
    mounted() {
        this.value = this.note
    }
}
</script>

<style>
.save {
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.delete {
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.editable {
    width: 100%;
    /*font-size: 1.3em;*/
    /*line-height: 1.3em;*/
    padding: 0px 4px;
}
.editable:hover:before {
    content: '✎';
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 20px);
    font-size: 30px !important;
}
[contenteditable]:focus {
    outline: 1px solid #3C6932;
    padding: 4px;
    font-size: 1.3em;
    line-height: 1.3em;
}
</style>